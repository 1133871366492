export default class POI {
  open = false;

  constructor(type, code, color, venues) {
    this.type = type;
    this.code = code;
    this.color = color;
    this.venues = venues;
  }

  toggleOpen() {
    if (this.venues.length) {
      this.open = !this.open;
    }
  }

  addVenue(venue) {
    this.venues.push(venue);
  }

  isType(catID) {
    return this.code.includes(catID);
  }
}