import React, { useState, useEffect, Fragment } from 'react'
import { Box, Typography, Input, List, ListItem, ListItemIcon, ListItemText, Collapse, makeStyles } from '@material-ui/core';
import { KeyboardArrowRight, KeyboardArrowDown } from '@material-ui/icons';

import venuesJSON from './venues.json';
import MapIcon from './MapIcon';
import grey from '@material-ui/core/colors/grey';
import POI from './poi';

const useStyles = makeStyles({
  listItem: {
    paddingLeft: '30px !important',
  },
  listItemIcon: {
    minWidth: 50,
  },
});

const Sidebar = ({ token, address, setAddress, pois, setPlace, venues, setPOIs }) => {
  const [locationString, setLocationString] = useState('');
  const classes = useStyles();

  const handleSubmit = (event) => {
    event.preventDefault();

    // Don't query if string is empty
    if (!locationString) return;

    fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURI(locationString)}.json?access_token=${token}`
    )
      .then(res => res.json())
      .then((res) => {
        const [primaryFeat] = res.features;

        switch (primaryFeat.place_type[0]) {
          case 'address':
            setPlace(null);
            setAddress(primaryFeat);
            break;
          case 'postcode':
          case 'place':
          case 'locality':
            setAddress(null);
            setPlace(primaryFeat);
            break;
          default:
            console.log('UNHANDLED');
        }
      })
  };

  useEffect(() => {
    if (address) setLocationString(address.place_name);
  }, [address])

  useEffect(() => {
    if (venues) {
      const pois = {};
      venuesJSON.forEach(({ type, code, color }) => {
        pois[type] = new POI(type, code, color, []);
      });
      venues.forEach(venue => {
        const [category] = venue.categories;
        Object
          .keys(pois)
          .forEach(
            (type, i) => {
              if (venue.location.address && pois[type].code.includes(category.id)) {
                venue.icon = i;
                pois[type].addVenue(venue);
              }
            });
      });
      setPOIs(pois);
    }
  }, [setPOIs, venues])

  useEffect(() => {
    !address && setPOIs(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const toggleCollapse = (poi) => {
    poi.toggleOpen();
    setPOIs({ ...pois });
  }

  return (
    <Box
      style={{ width: '475px', height: '100%' }}
      display="flex"
      flexDirection="column"
    >
      <Box
        style={{
          height: '180px',
          color: grey[100],
          background: '#232538',
          padding: '40px 30px',
          borderRight: '1px solid #3E4053',
        }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexShrink={0}
      >
        <Box>
          <Typography variant="h3">
            <Box fontWeight="bold">
              UpsideMaps
            </Box>
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ maxWidth: '100%', lineHeight: '1.5', marginTop:'15px', marginBottom:'15px'}}
          >
            Get a quick snapshot of any property in seconds. Best viewed on your desktop.
          </Typography>
        </Box>


        <form onSubmit={handleSubmit}>
          <Input
            style={{
              background: '#D4D5DF',
              borderRadius: '4px',
              padding: '10px 10px',
              width: '100%',
            }}
            disableUnderline={true}
            autoFocus={true}
            placeholder="Enter an address and hit return to search"
            value={locationString}
            onChange={e => setLocationString(e.target.value)}
          />
        </form>
      </Box>
      <Box
        style={{ borderRight: `1px solid ${grey[500]}`, overflowY: 'auto' }}
        flexGrow={1}
      >
        {
          pois &&
          <List disablePadding={true}>
            {
              Object
                .values(pois)
                .map((poi, i) => (
                  <Fragment>
                    <Box
                      boxShadow={0}
                      fontWeight="fontWeightBold"
                      fontSize={16}
                    >
                      <ListItem
                        button
                        onClick={() => toggleCollapse(poi)}
                        style={{
                          backgroundColor: poi.color,
                          paddingLeft: '35px',
                          paddingTop: '18px',
                          paddingBottom: '18px',
                          marginBottom: '0px'
                        }}
                        classes={{
                          root: classes.listItem,
                        }}
                      >
                        <ListItemIcon classes={{ root: classes.listItemIcon }}>
                          <MapIcon icon={i} />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${poi.venues.length} ${poi.type} Nearby`}
                          disableTypography={true}
                        />
                        {poi.open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                      </ListItem>
                    </Box>
                    <Collapse in={poi.open}>
                      <List
                        disablePadding={false}
                        dense={false}
                        style={{ maxHeight: '100%', overflowY: 'auto' }}
                      >
                        {
                          poi.venues.map((venue) => (
                            <ListItem style={{ padding: '5px 40px 5px', display: 'block' }}>
                              <ListItemText
                                primary={venue.name}
                                primaryTypographyProps={{ variant: 'subtitle1' }}
                                secondaryTypographyProps={{ variant: 'subtitle2' }}
                                secondary={`${venue.categories[0].name} on ${venue.location.address}`}
                                style={{ marginBottom: 5 , borderBottom: '0px solid #E5E5E5',}}
                              />
                            </ListItem>
                          ))
                        }
                      </List>
                    </Collapse>
                  </Fragment>
                ))
            }
          </List>
        }
      </Box>
    </Box>
  );
}

export default Sidebar;