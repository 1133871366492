/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { render } from 'react-dom';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';

import RadiusSlider from './RadiusSlider';
import AddressMarker from './AddressMarker';
import MapIcon from './MapIcon';
import venues from './venues.json';

const getCirclePaint = (center, radius) => {
  return ({
    'circle-color': '#232538',
    'circle-opacity': 0.2,
    'circle-radius': {
      stops: [
        [0, 0],
        [20, (radius / 0.00062137) / 0.075 / Math.cos(center[1] * Math.PI / 180)]
      ],
      base: 2,
    }
  })
};

const Map = ({ token, address, place, pois, setVenues }) => {
  const [map, setMap] = useState(null);
  const [addressMarker, setAddressMarker] = useState(null);
  const [radius, setRadius] = useState(1);
  const [iconMarkers, setIconMarkers] = useState([]);

  const mapContainer = useRef(null);

  // Initialize Map
  useEffect(() => {
    mapboxgl.accessToken = token;

    const initializeMap = ({ setMap, mapContainer }) => {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/theloco/ckdpajuzf00zr1imw4uejkaqe',
        attributionControl: false,
        logoPosition: 'bottom-right',
        center: [-117.969, 34.005],
        zoom: 9,
        pitch: 20,
      });

      map.on("load", () => {
        setMap(map);
        map.resize();
      });
    };

    if (!map) initializeMap({ setMap, mapContainer });
  }, [map, token]);

  // Remove address marker if address changed
  useEffect(() => {
    if (addressMarker) {
      addressMarker.remove();
      map.removeLayer('radar');
      map.removeSource('radar_source');
      setAddressMarker(null);
    }
  }, [address]);


  // On set of address, create address marker and radar
  useEffect(() => {
    if (address) {
      const element = document.createElement('div');
      render(<AddressMarker />, element);

      const { center } = address;
      const m = new mapboxgl.Marker({ element });
      m.setLngLat(center).addTo(map);
      setAddressMarker(m);

      map.addSource('radar_source', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: center,
          },
          properties: {}
        }
      });

      map.addLayer({
        id: 'radar',
        type: 'circle',
        source: 'radar_source',
        paint: getCirclePaint(center, radius),
      });

      // Sets map focus to address center, with predetermined zoom
      // TODO: Figure how to fit the current source
      map.fitBounds(
        turf.bbox(turf.circle(center, radius, { units: 'miles' })),
        { padding: 50 },
      );
    }
  }, [address])

  // Handle fitting place's bbox when set
  useEffect(() => {
    if (place) {
      map.fitBounds(place.bbox);
    }
  }, [place]);

  // Resize radar whenever radius slider changes
  useEffect(() => {
    if (map && address) {
      map.fitBounds(turf.bbox(turf.circle(address.center, radius, { units: 'miles' })));
      map.setPaintProperty('radar', 'circle-radius', {
        stops: [
          [0, 0],
          [20, (radius / 0.00082137 / (78271.484 / 2 ** 20) / Math.cos(address.center[1] * Math.PI / 180))]
        ],
        base: 2,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, radius]);

  // TODO: Create a custom HOOK with the debounce functionality wh/ 
  // returns the useCallback
  const debounce = function (func, wait) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, wait);
    };
  }

  const debounceVenueSearch = useCallback(
    debounce(function (radius, address) {
      const params = new URLSearchParams();
      params.append('client_id', process.env.REACT_APP_FOURSQUARE_CLIENT_ID);
      params.append('client_secret', process.env.REACT_APP_FOURSQUARE_CLIENT_SECRET);
      params.append('ll', [...address.center].reverse().toString());
      params.append('radius', (radius / 0.00082137).toString());
      params.append('v', '20220701');
      params.append('categoryId', venues.map(venue => venue.code).toString());
      params.append('limit', '50');
      fetch(`https://api.foursquare.com/v2/venues/search?${params.toString()}`)
        .then(res => res.json())
        .then(res => setVenues(res.response.venues))
    }, 100),
    [],
  );

  // When radius or address change trigger a new query to the Venue's API
  useEffect(() => {
    if (address) {
      debounceVenueSearch(radius, address);
    }
  }, [radius, address])

  // Remove the icon markers when the radius has changed (triggers new query)
  // or pois changed (new query or set to empty)
  useEffect(() => {
    iconMarkers.forEach((marker) => marker.remove());
  }, [radius, pois]);

  useEffect(() => {
    if (pois) {
      const ms = [];
      Object
        .values(pois)
        .forEach((poiType) => {
          poiType
            .venues
            .forEach((venue) => {
              const el = document.createElement('div');
              render(<MapIcon icon={venue.icon} />, el);
              const { lng, lat } = venue.location;
              const m = new mapboxgl.Marker({ element: el });
              m.setLngLat([lng, lat]).addTo(map);
              ms.push(m);
            });
        })
      setIconMarkers(ms);
    }
  }, [pois]);

  return (
    <div
      id="map"
      ref={el => (mapContainer.current = el)}
      style={{
        height: '100%',
        flex: 1
      }}
    >
      <RadiusSlider
        map={map}
        radius={radius}
        setRadius={setRadius}
        address={address}
      />
    </div>
  );
};

export default Map;
