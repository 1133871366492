import React from 'react';

const AddressMarker = () => (
  <div
    style={{
      height: '24px',
      width: '24px',
      borderRadius: '50%',
      backgroundColor: '#3E4053',
      border: '3px solid white',
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 5px 8px 0px, rgba(0, 0, 0, 0.12) 0px 1px 14px 0px',
    }}
  />
);

export default AddressMarker;
