import React from 'react';

import { ReactComponent as SchoolSVG } from './assets/svg/school-15.svg';
import { ReactComponent as ParkAlt1SVG } from './assets/svg/park-alt1-15.svg';
import { ReactComponent as ShopSVG } from './assets/svg/shop-15.svg';
import { ReactComponent as CafeSVG } from './assets/svg/cafe-15.svg';
import { ReactComponent as RoadblockSVG } from './assets/svg/roadblock-15.svg';
import { ReactComponent as CommTowerSVG } from './assets/svg/communications-tower-15.svg';
import { ReactComponent as IndustrySVG } from './assets/svg/industry-15.svg';
import { ReactComponent as HospitalSVG } from './assets/svg/hospital-JP-15.svg';

const icons = [
  SchoolSVG.render(),
  ParkAlt1SVG.render(),
  ShopSVG.render(),
  CafeSVG.render(),
  RoadblockSVG.render(),
  CommTowerSVG.render(),
  IndustrySVG.render(),
  HospitalSVG.render(),
];

const MapIcon = ({ icon, name }) => (
  <div
    style={{
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }}>
    {icons[icon]}
    <span>{name}</span>
  </div>
);

export default MapIcon;
