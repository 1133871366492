import React, { useState } from 'react';
import { render } from 'react-dom';
import { useEffect, useRef } from 'react';
import Slider from '@material-ui/core/Slider';

/* eslint-disable no-unused-expressions */

const RadiusSlider = ({ map, address, setRadius, radius }) => {
  const sliderContainer = useRef();
  const [sliderAdded, setSliderAdded] = useState(false);

  const handleSliderChange = (event, newValue) => {
    setRadius(newValue);
  }

  const sliderControl = {
    onAdd(map) {
      setSliderAdded(true);
      return sliderContainer.current;
    },
    onRemove() {
      setSliderAdded(false);
      sliderContainer.current?.parentNode?.removeChild(sliderContainer.current);
    }
  }

  useEffect(() => {
    if (map && address && !sliderAdded) {
      map.addControl(sliderControl, 'bottom-left');
    }

    if (map && !address && sliderAdded) {
      map.removeControl(sliderControl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, address]);

  return (
    <div ref={el => (sliderContainer.current = el)}>
      <div
        className="mapboxgl-ctrl"
        style={{
          margin: '25px 50px !important',
          height: '35px',
          padding: '0 10px',
          width: '250px',
          backgroundColor: 'white',
          borderRadius: '10px',
          border: `2px solid lightgray`,
          display: sliderAdded ? 'block' : 'none',
        }}
      >
        <Slider
          value={radius}
          onChange={handleSliderChange}
          min={1}
          max={15}
          color="primary"
          valueLabelDisplay="on"
          valueLabelFormat={value => `${value}mi`}
          aria-labelledby="continuous-slider"
        />
      </div>
    </div>
  );
}

export default RadiusSlider;