import React, { useEffect, useState } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import "mapbox-gl/dist/mapbox-gl.css";

import Map from './Map';
import Sidebar from './Sidebar'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: grey[900],
    },
  },
});

function App() {
  const ACCESS_TOKEN =
    'pk.eyJ1IjoidGhlbG9jbyIsImEiOiJja2VsemdoN2IwMjJsMnJzYXVqOGNpdTBnIn0.R_EVQbhrYlocO_tXfyX3Iw';

  const [address, setAddress] = useState(null);
  const [place, setPlace] = useState(null);
  const [venues, setVenues] = useState(null);
  const [pois, setPOIs] = useState(null);

  return (
    <ThemeProvider theme={theme}>
      <div
        className="App"
        style={{ height: '100vh', display: 'flex' }}
      >
        <Sidebar
          token={ACCESS_TOKEN}
          address={address}
          venues={venues}
          pois={pois}
          setPOIs={setPOIs}
          setAddress={setAddress}
          setPlace={setPlace}
        />
        <Map
          token={ACCESS_TOKEN}
          place={place}
          address={address}
          pois={pois}
          setVenues={setVenues}
        />
      </div>
    </ThemeProvider>
  );
}

export default App;